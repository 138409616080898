<template>
  <div class="resource-table">
    <table id="resources-table">
      <thead v-if="selectable.getIds().length === 0">
        <tr>
          <th
            v-for="(columnData, columnName) in columns"
            :key="columnName"
            :style="
              columnData.style
                ? columnData.style
                : columnData.kind === 'customer'
                ? 'padding: 20px px;'
                : columnData.kind === 'url'
                ? 'padding: 20px 0; width: 400px'
                : columnData.kind === 'title-menufilter'
                ? 'width: 150px'
                : columnData.kind === 'value-menufilter'
                ? 'width: 550px'
                : ''
            "
            :class="columnData.kind === 'numberOfUpdated' ? 'w-100' : ''"
          >
            <!-- <template v-if="columnData.kind === 'draggerable'">
              <span></span>
            </template> -->
            <!-- <div>{{ selectable.getIds().length }}</div> -->
            <template v-if="!columnData.blankLabel">{{ columnName }}</template>

            <!-- Selectable -->
            <v-tooltip top nudge-bottom="5" z-index="109999990" color="primary">
              <template v-slot:activator="{ on, attrs }">
                <template v-if="columnData.kind === 'selectable'">
                  <div v-bind="attrs" v-on="on" class="custom-control custom-checkbox table-checkbox pointer">
                    <input
                      @change="onchangeSelectable"
                      :checked="selectable.isSelectAll()"
                      type="checkbox"
                      class="custom-control-input pointer"
                      name="ordersSelect"
                      :id="selectableId"
                    />
                    <!-- là label của PRODUCT -->
                    <label class="custom-control-label pointer" :for="selectableId">&nbsp;</label>
                  </div>
                </template>
              </template>
              <span>Select all products</span>
            </v-tooltip>
          </th>
        </tr>
      </thead>
      <tbody v-if="!$parent.isLoading && document && document.length > 0 && !draggable">
        <tr @mouseover="mouseOver(k)" @mouseleave="mouseLeave(k)" v-for="(resource, k) in document" :key="k">
          <td
            v-for="(column, kc) in columns"
            :key="kc"
            :style="
              column.kind === 'selectable'
                ? 'width:15px'
                : column.kind === 'customer'
                ? 'width:165px !important; padding: 20px 0; word-wrap: break-word; word-break: break-all'
                : column.kind === 'customerAbandoned'
                ? 'width:130px !important; word-wrap: break-word; word-break: break-all'
                : column.kind === 'url'
                ? 'padding: 20px 0; width: 400px'
                : ''
            "
          >
            <template v-if="column.kind === 'action'">
              <!-- <div @click="deleteResource(resource)">
                  <i class="fas fa-trash-alt pointer"></i>
                </div> -->
              <div class="d-flex justify-start align-center">
                <span v-for="type in column.listAction" :key="type.name">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="grey"
                        icon
                        v-bind="attrs"
                        v-on="on"
                        @click="callbackAction({ action: type.name }, resource._id)"
                      >
                        <i class="fas fa-trash-alt pointer" v-if="type.name === 'delete'"></i>
                        <i class="fas fa-copy pointer" v-if="type.name === 'duplicate'"></i>
                        <i
                          class="far fa-copy pointer"
                          v-if="type.name === 'bulk-duplicate' && resource.status === 3"
                        ></i>
                        <i class="fas fa-rocket" v-if="type.name === 'relaunch'"></i>
                      </v-btn>
                    </template>
                    <span class="text-capitalize">{{ type.name }}</span>
                  </v-tooltip>
                </span>
              </div>
              <!-- <div>
                  <i class="fas fa-edit"></i>
                </div> -->
            </template>
            <template v-if="column.kind === 'selectable'">
              <div style="width: 15px" class="custom-control custom-checkbox table-checkbox pointer">
                <input
                  @change="onchangeSelectableElement(resource)"
                  :checked="selectable.isSelected(makeSelectId(resource))"
                  type="checkbox"
                  class="custom-control-input pointer"
                  name="ordersSelect"
                  :id="makeSelectId(resource)"
                />
                <label :for="makeSelectId(resource)" class="custom-control-label">&nbsp;</label>
              </div>
            </template>
            <template v-if="column.kind === 'viewProduct'">
              <div class="d-flex align-center">
                <v-avatar tile size="40" height="auto" color="#dddd" class="text-center pointer mr-3">
                  <img
                    :src="
                      resource.imageSrcs && resource.imageSrcs.length > 0 && resource.imageSrcs[0]
                        ? imageHelpers.url(resource.imageSrcs[0])
                        : imageHelpers.url(
                            resource.images && resource.images.length > 0 && resource.images[0].src
                              ? resource.images[0].src
                              : '/admin/img/no-image.png',
                          )
                    "
                    alt=""
                    srcset=""
                  />
                </v-avatar>
                <div>
                  <router-link class="invent" :to="{ name: routeName, params: { id: resource._id } }">{{
                    utilities.stringLimit(resource[column.field], 40)
                  }}</router-link>
                  <div v-if="!resource.published" class="unavailable">Unavailable</div>
                </div>
                <v-spacer />
                <!-- <span v-if="!hover"></span> -->
                <i @click.stop="gotoProduct(resource)" class="ml-5 pointer show-password far fa-eye fz-16"></i>
                <i
                  v-if="column.kind === 'viewProductP'"
                  @click.stop="gotoPersonalize(resource)"
                  class="pointer mx-2 fab fa-intercom"
                ></i>
              </div>
            </template>
            <template v-if="column.kind === 'viewProductinPer'">
              <div class="d-flex align-center">
                <v-avatar tile size="40" color="#dddd" class="text-center pointer mr-3">
                  <img
                    :src="resource.images[0] ? imageHelpers.url(resource.images[0].src) : '/admin/img/no-image.png'"
                    alt=""
                    srcset=""
                  />
                </v-avatar>
                <div>
                  <router-link class="invent" :to="{ name: `website.products.update`, params: { id: resource._id } }">{{
                    utilities.stringLimit(resource[column.field], 40)
                  }}</router-link>
                  <div v-if="!resource.published" class="unavailable">Unavailable</div>
                </div>
                <v-spacer />
                <i @click.stop="gotoProduct(resource)" class="ml-5 pointer show-password far fa-eye fz-16"></i>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <i
                      v-bind="attrs"
                      v-on="on"
                      v-if="column.kind === 'viewProductinPer'"
                      @click.stop="gotoPersonalize(resource)"
                      class="pointer mx-2 fab fa-intercom"
                    ></i>
                  </template>
                  <span>Setup Personalized Product</span>
                </v-tooltip>
              </div>
            </template>
            <template v-if="column.kind === 'viewProductinPerText'">
              <div class="d-flex align-center">
                <v-avatar tile size="40" color="#dddd" class="text-center pointer mr-3">
                  <img
                    :src="resource.images[0] ? imageHelpers.url(resource.images[0].src) : '/admin/img/no-image.png'"
                    alt=""
                    srcset=""
                  />
                </v-avatar>
                <div>
                  <router-link class="invent" :to="{ name: `website.products.update`, params: { id: resource.xId } }">{{
                    utilities.stringLimit(resource[column.field], 40)
                  }}</router-link>
                  <div v-if="!resource.published" class="unavailable">Unavailable</div>
                </div>
              </div>
            </template>
            <template v-if="column.kind === 'viewProductPersonalize'">
              <div class="d-flex align-center">
                <v-avatar tile size="40" color="#dddd" class="text-center pointer mr-3">
                  <img
                    :src="resource.images[0] ? imageHelpers.url(resource.images[0].src) : '/admin/img/no-image.png'"
                    alt=""
                    srcset=""
                  />
                </v-avatar>
                <router-link class="invent" :to="{ name: routeName, params: { id: resource._id } }">{{
                  utilities.stringLimit(resource[column.field], 40)
                }}</router-link>
                <!-- <span @click="updateRouterCallback(resource._id)" class="table-link">
                    {{ utilities.stringLimit(resource[column.field], 40) }}
                  </span> -->
                <v-spacer />
                <i @click.stop="gotoProduct(resource)" class="ml-5 pointer show-password far fa-eye fz-16"></i>

                <!-- <i @click.stop="gotoProduct(resource)" class="ml-5 pointer show-password far fa-eye fz-16"></i> -->
                <!-- <i @click.stop="gotoPersonalize(resource)" class="pointer mx-2 fab fa-intercom"></i> -->
              </div>
            </template>
            <template v-if="column.kind === 'viewCollection'">
              <div @click="updateRouterCallback(resource._id)" class="table-link">
                <v-avatar tile size="40" color="#dddd" class="text-center pointer mr-3">
                  <img
                    :src="
                      resource.image && resource.image.src !== '' && resource.image.src !== null
                        ? imageHelpers.url(resource.image.src)
                        : '/admin/img/no-image.png'
                    "
                    alt=""
                    srcset=""
                  />
                </v-avatar>
                <router-link class="invent" :to="{ name: routeName, params: { id: resource._id } }">{{
                  utilities.stringLimit(resource[column.field], 40)
                }}</router-link>
                <!-- <span>
                    {{ utilities.stringLimit(resource[column.field], 50) }}
                  </span> -->
              </div>
            </template>
            <template v-if="column.kind === 'text'">
              <div
                :class="{
                  'text-capitalize': resource[column.field] != 'cod',
                  'text-uppercase': resource[column.field] == 'cod',
                }"
              >
                {{ resource[column.field] }}
              </div>
            </template>
            <!-- status in abandoned checkout -->
            <template v-if="column.kind === 'text-status'">
              <div class="text-capitalize">
                {{ changeName(resource[column.field]) }}
              </div>
            </template>
            <template v-if="column.kind === 'migrate-status'">
              <div class="text-capitalize">
                <span v-if="resource[column.field] === 0">Not in sync</span>
                <span v-if="resource[column.field] === 1">Syncing</span>
                <span v-if="resource[column.field] === 2">Synced</span>
                <span v-if="resource[column.field] === 3">Sync error.</span>
              </div>
            </template>
            <template v-if="column.kind === 'title-campaign'">
              <!-- <div>
                <router-link :to="{ path: routeName, params: { id: resource._id } }">{{ resource.name }}</router-link>
              </div> -->
              <div
                class="pointer d-flex align-center"
                @click="updateRouterCallback(resource._id ? resource._id : resource.id)"
              >
                <v-avatar tile size="40" height="auto" color="#dddd" class="text-center pointer mr-3">
                  <img
                    :src="resource.imageSrc ? imageHelpers.url(resource.imageSrc) : '/admin/img/no-image.png'"
                    alt=""
                    srcset=""
                  />
                </v-avatar>
                <div class="table-link">{{ utilities.stringLimit(resource.title, 50) }}</div>
              </div>
            </template>
            <template v-if="column.kind === 'status-campaign'">
              <!-- <div>
                <router-link :to="{ path: routeName, params: { id: resource._id } }">{{ resource.name }}</router-link>
              </div> -->
              <div class="pointer" @click="updateRouterCallback(resource._id ? resource._id : resource.id)">
                <span v-if="resource.status === 0">
                  <v-chip small outlined class="ma-2" color="red" label> Error </v-chip></span
                >
                <span v-if="resource.status === 1">
                  <v-chip small outlined class="ma-2" color="red" label> Draft </v-chip></span
                >
                <span v-if="resource.status === 3">
                  <v-chip small outlined class="ma-2" color="#4181cb" label> Active </v-chip></span
                >
                <span v-if="resource.status === 2">
                  <v-chip small outlined class="ma-2" color="#fd7e14" label>
                    <i class="fas fa-sync mr-1"></i> Launch
                  </v-chip></span
                >
              </div>
            </template>
            <template v-if="column.kind === 'title-menufilter'">
              <!-- <div>
                  <router-link :to="{ path: routeName, params: { id: resource._id } }">{{ resource.name }}</router-link>
                </div> -->
              <div class="pointer" @click="updateRouterCallback(resource._id ? resource._id : resource.id)">
                <div class="table-link">{{ utilities.stringLimit(resource.name, 50) }}</div>
              </div>
            </template>
            <template v-if="column.kind === 'value-menufilter'">
              <div>
                {{ resource.values.length > 0 ? getColorName(resource) : '' }}
                <!-- {{ resource.values }} -->
              </div>
            </template>
            <template v-if="column.kind === 'text2'">
              <div class="">
                {{ resource[column.field] }}
              </div>
            </template>
            <template v-if="column.kind === 'sku'">
              <div style="max-width: 80px; overflow: hidden; text-overflow: ellipsis" class="">
                {{ resource[column.field] }}
              </div>
            </template>
            <template v-if="column.kind === 'text-inventory'">
              <div class="">
                {{ resource[column.field] == 'continue' ? 'Continue selling' : 'Stop selling' }}
              </div>
            </template>
            <template v-if="column.kind === 'product-variant'">
              <div style="width: 150px">
                <router-link
                  class="invent"
                  :to="{ name: 'website.products.update', params: { id: resource[column.product]._id } }"
                >
                  {{ utilities.stringLimit(resource[column.product].title, 20) }}
                </router-link>
              </div>
              <div class="">{{ resource[column.field] }}</div>
            </template>
            <template v-if="column.kind === 'update-quantity'">
              <div class="d-flex align-center custom-input">
                <v-btn-toggle>
                  <v-btn
                    :color="actions[k].data.action == 'add' ? 'primary' : ''"
                    @click="actions[k].data.action = 'add'"
                    small
                    >ADD
                  </v-btn>
                  <v-btn
                    :color="actions[k].data.action == 'set' ? 'primary' : ''"
                    @click="actions[k].data.action = 'set'"
                    small
                    >SET
                  </v-btn>
                </v-btn-toggle>
                <v-text-field
                  style="width: 70px"
                  class="mt-0 input-no-boder-radius"
                  width="50px"
                  height="30px"
                  type="number"
                  hide-details
                  v-model="actions[k].data.inventoryQuantity"
                ></v-text-field>
                <v-btn small @click="updateQuantityCallback(actions[k])" color="primary">SAVE</v-btn>
              </div>
            </template>
            <template v-if="column.kind === 'title'">
              <div class="pointer" @click="updateRouterCallback(resource._id ? resource._id : resource.id)">
                <div class="table-link">
                  <router-link
                    class="invent"
                    :to="{ name: routeName, params: { id: resource._id ? resource._id : resource.id } }"
                    ><span>{{ utilities.stringLimit(resource[column.field], 50) }}</span></router-link
                  >
                </div>
              </div>
            </template>
            <template v-if="column.kind === 'title-sizechart'">
              <div class="pointer" @click="updateRouterCallback(resource._id ? resource._id : resource.id)">
                <div class="table-link">
                  <!-- có 1 số shop cũ size chart ở dạng string, khi load trang nó lỗi luôn, nên phải ntn -->
                  <router-link
                    class="invent"
                    :to="{ name: routeName, params: { id: resource._id ? resource._id : resource.id } }"
                    ><span>{{
                      typeof resource[column.field] != 'string'
                        ? utilities.stringLimit(resource[column.field].join(', '), 60)
                        : utilities.stringLimit(resource[column.field], 60)
                    }}</span></router-link
                  >
                </div>
              </div>
            </template>
            <template v-if="column.kind === 'viewOrderCheckRole'">
              <div
                v-if="resource.offerType === 'postPurchase'"
                class="pointer"
                @click="
                  utilities.checkPermisson(`write_orders`, user)
                    ? checkRoleWriteOrder(resource, 3)
                    : $router.push({ name: 'no-access' })
                "
              >
                <div class="table-link">
                  <router-link
                    class="invent"
                    :to="{ name: routeName, params: { id: resource._id ? resource._id : resource.id } }"
                    ><span>{{ utilities.stringLimit(resource[column.field], 50) }}</span></router-link
                  >
                </div>
              </div>
              <div
                v-else
                class="pointer"
                @click="
                  utilities.checkPermisson(`write_orders`, user)
                    ? checkRoleWriteOrder(resource)
                    : $router.push({ name: 'no-access' })
                "
              >
                <div class="table-link">
                  <router-link
                    class="invent"
                    :to="{ name: routeName, params: { id: resource._id ? resource._id : resource.id } }"
                    ><span>{{ utilities.stringLimit(resource[column.field], 50) }}</span></router-link
                  >
                </div>
                <!-- <span> {{ utilities.stringLimit(resource[column.field], 50) }} </span> -->
              </div>
            </template>
            <template v-if="column.kind === 'view'">
              <div
                v-if="resource.offerType === 'postPurchase'"
                class="pointer"
                @click="updateRouterCallback(resource._id ? resource._id : resource.id, { tab: 3 })"
              >
                <div class="table-link">
                  <router-link
                    class="invent"
                    :to="{ name: routeName, params: { id: resource._id ? resource._id : resource.id } }"
                    ><span> {{ utilities.stringLimit(resource[column.field], 50) }} </span></router-link
                  >
                </div>
              </div>
              <div v-else class="pointer" @click="updateRouterCallback(resource._id ? resource._id : resource.id)">
                <div class="table-link">
                  <router-link
                    class="invent"
                    :to="{ name: routeName, params: { id: resource._id ? resource._id : resource.id } }"
                    ><span> {{ utilities.stringLimit(resource[column.field], 50) }} </span></router-link
                  >
                </div>
              </div>
            </template>
            <template v-if="column.kind === 'checkoutAbandon'">
              <div class="pointer" @click="updateRouterCallback(resource._id ? resource._id : resource.id)">
                <!-- <router-link
                      class="table-link"
                      :to="{ name: routeName, params: { id: resource._id ? resource._id : resource.id } }"
                      >{{ utilities.stringLimit(resource[column.field], 50) }}</router-link
                    > -->
                <div class="table-link">
                  <router-link
                    class="invent"
                    :to="{ name: routeName, params: { id: resource._id ? resource._id : resource.id } }"
                    ><span> {{ utilities.stringLimit(resource[column.field], 50) }} </span></router-link
                  >
                </div>
              </div>
            </template>
            <template v-if="column.kind === 'price'">
              <div class="text-end error--text font-weight-medium">
                {{ currency.priceFormat(resource[column.field]) }}
              </div>
            </template>
            <template v-if="column.kind === 'percent'">
              <div class="text-end error--text font-weight-medium">
                {{ resource[column.field] }}
              </div>
            </template>

            <template v-if="column.kind === 'customerAbandoned'">
              <div class="text-left">
                {{ utilities.stringLimit(resource[column.field] ? resource[column.field].full_name : '', 18) }}
              </div>
            </template>
            <template v-if="column.kind === 'customer'">
              <v-hover v-slot="{ hover }">
                <div class="d-flex" style="">
                  <!-- {{ utilities.stringLimit(resource[column.field].email, 16) }} -->
                  <!-- {{ resource[column.field].email }} -->
                  {{
                    utilities.stringLimit(
                      resource[column.field].length > 0 ? resource[column.field][0].full_name : '',
                      18,
                    )
                  }}
                  <!-- <v-spacer></v-spacer> -->
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-show="hover"
                        @click.stop="gotoCustomer(resource)"
                        class="ml-1 mdi mdi-account-search mdi-18px"
                        v-bind="attrs"
                        v-on="on"
                      ></v-icon>
                    </template>
                    <span>View customer</span>
                  </v-tooltip>
                </div>
              </v-hover>
            </template>
            <template v-if="column.kind === 'rules'">
              <div v-for="(rule, k) in resource[column.field]" class="" :key="k">
                <span v-if="getColumn(rule.column)">
                  {{ utilities.stringLimit(getColumn(rule.column).name, 50) }}
                </span>
                <span v-if="getRule(rule.relation)">
                  {{ utilities.stringLimit(getRule(rule.relation).name, 50) }}
                </span>
                {{ utilities.stringLimit(rule.condition, 10) }}
              </div>
            </template>
            <template v-if="column.kind === 'datetime'">
              <div v-if="resource[column.field]">
                <span v-if="column.type && column.type === 'utc'">
                  {{ datetime.formatUTC(resource[column.field], 'DD-MM-YYYY HH:mm:ss') }}
                </span>
                <span v-else>{{ datetime.format(resource[column.field], 'DD-MM-YYYY HH:mm:ss') }}</span>
              </div>
            </template>
            <template v-if="column.kind === 'date'">
              <div v-if="resource[column.field]">
                <span v-if="column.type && column.type === 'utc'">
                  {{ datetime.formatUTC(resource[column.field], 'DD-MM-YYYY HH:mm:ss') }}
                </span>
                <span v-else>{{ datetime.format(resource[column.field], 'DD-MM-YYYY HH:mm:ss') }}</span>
              </div>
            </template>
            <template v-if="column.kind === 'status'">
              <v-chip
                v-if="
                  resource[column.field] == 'active' ||
                    resource[column.field] == 'deny' ||
                    resource[column.field] == 'deactivate'
                "
                :color="resource[column.field] == 'active' ? 'green' : ''"
                outlined
              >
                {{ resource[column.field] }}
              </v-chip>
              <p v-else>
                <v-chip :color="resource[column.field] == true ? 'green' : ''" outlined>
                  {{ resource[column.field] == 1 ? 'Ready' : 'Disabled' }}
                </v-chip>
              </p>
            </template>
            <template v-if="column.kind === 'status-feed'">
              <v-chip v-if="resource[column.field] == 0">Wating</v-chip>
              <v-chip v-if="resource[column.field] == 1" class="orange">Processing</v-chip>
              <v-chip v-if="resource[column.field] == 2" color="green">Completed</v-chip>
              <v-chip v-if="resource[column.field] == 4" color="red">Error</v-chip>
            </template>
            <template v-if="column.kind === 'url'">
              <a @click="download(resource[column.field])">{{ utilities.stringLimit(resource[column.field], 40) }}</a>
              <v-icon @click="copyURL(resource[column.field])" class="ml-3">far fa-copy</v-icon>
              <v-chip
                v-if="resource.totalKlaviyoFile"
                class="ml-2 pointer"
                @click="showListKlaviyo(resource.totalKlaviyoFile, resource._id)"
              >
                Klaviyo
              </v-chip>
              <!-- <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-on="on"
                    v-bind="attrs"
                    @click="showListKlaviyo(resource.totalKlaviyoFile, resource._id)"
                    class="ml-3"
                  >
                    mdi mdi-alpha-k
                  </v-icon>
                </template>
                <span>Klaviyo</span>
              </v-tooltip> -->
            </template>

            <!-- //showOffer -->
            <template v-if="column.kind === 'priority-custom'">
              <div style="width: 50px">
                <v-text-field
                  :error="resource.priority < 0 ? true : false"
                  @blur="resource.priority > 0 ? submitPriority(resource) : null"
                  type="number"
                  min="1"
                  hide-details
                  v-model.number="resource.priority"
                ></v-text-field>
              </div>
            </template>
            <template v-if="column.kind === 'showOffer'">
              <div v-if="resource.targetType === 'all'" class="d-flex align-center">
                <div class="image__container mr-1"><div class="placeholder style-1">A</div></div>
                Any product
              </div>
              <!-- khi Choose Bundle products chọn Smart -->
              <div
                v-if="resource.targetType === 'product' && resource.typeBundle == 'smart'"
                class="d-flex align-center"
              >
                <div class="image__container mr-3"><div class="placeholder style-1">A</div></div>
                Any product
              </div>
              <div
                v-if="resource.recommendType === 'product' && resource.offerType === 'handpicked'"
                class="d-flex flex-column"
              >
                <div v-for="(product, k) in resource.productRecommendIds" :key="product._id" class="pointer">
                  <div
                    @click="gotoProduct(product)"
                    v-if="k <= 2 || !resource.seeMore"
                    class="pt-1 d-flex align-center"
                  >
                    <v-avatar tile size="32" color="#dddd" class="text-center pointer mr-3">
                      <img
                        :src="
                          product && product.images[0]
                            ? imageHelpers.url(product.images[0].src)
                            : '/admin/img/no-image.png'
                        "
                        alt=""
                        srcset=""
                      />
                    </v-avatar>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">{{ utilities.stringLimit(product.title, 20) }}</div>
                      </template>
                      <span> {{ product.title }}</span>
                    </v-tooltip>
                    <!-- <div>{{ utilities.stringLimit(product.title, 20) }}</div> -->
                  </div>
                  <div class="link-add" v-if="k == 3 && resource.seeMore">
                    <a href="#" @click="resource.seeMore = false"> See more </a>
                  </div>
                  <div class="link-add" v-if="k === resource.productRecommendIds.length - 1 && !resource.seeMore">
                    <a href="#" @click="resource.seeMore = true"> See less </a>
                  </div>
                </div>
              </div>
              <!-- khi Choose Bundle products chọn Specific -->
              <div
                v-if="resource.targetType === 'product' && resource.typeBundle == 'specific'"
                class="d-flex flex-column"
              >
                <div v-for="(product, k) in resource.productTargetIds" :key="product._id" class="pointer">
                  <div
                    @click="gotoProduct(product)"
                    v-if="k <= 2 || !resource.seeMore"
                    class="pt-1 d-flex align-center"
                  >
                    <v-avatar tile size="32" color="#dddd" class="text-center pointer mr-3">
                      <img
                        :src="
                          product && product.images[0]
                            ? imageHelpers.url(product.images[0].src)
                            : '/admin/img/no-image.png'
                        "
                        alt=""
                        srcset=""
                      />
                    </v-avatar>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">{{ utilities.stringLimit(product.title, 20) }}</div>
                      </template>
                      <span> {{ product.title }}</span>
                    </v-tooltip>
                  </div>
                  <div class="link-add" v-if="k == 3 && resource.seeMore">
                    <a href="#" @click="resource.seeMore = false"> See more </a>
                  </div>
                  <div class="link-add" v-if="k === resource.productTargetIds.length - 1 && !resource.seeMore">
                    <a href="#" @click="resource.seeMore = true"> See less </a>
                  </div>
                </div>
              </div>
              <div v-if="resource.targetType === 'collection'" class="d-flex flex-column">
                <div v-for="(collection, k) in resource.collectionTargetIds" :key="collection._id">
                  <div v-if="k <= 2 || !resource.seeMore" class="pt-1 d-flex align-center">
                    <div class="image__container mr-1"><div class="placeholder style-1">A</div></div>
                    <div @click="gotoCollectionStore(collection)" class="pointer">
                      {{ utilities.stringLimit(collection.title, 20) }}
                    </div>
                  </div>
                  <div class="link-add" v-if="k == 3 && resource.seeMore">
                    <a href="#" @click="resource.seeMore = false"> See more </a>
                  </div>
                </div>
              </div>
            </template>
            <template v-if="column.kind === 'showHandpickedTarget' && resource.offerType === 'handpicked'">
              <div
                v-if="resource.handpickedCollectionIds.length === 0 && resource.handpickedProductIds.length === 0"
                class="d-flex align-center"
              >
                <div class="image__container mr-1"><div class="placeholder style-1">A</div></div>
                Any product
              </div>
              <template>
                <div v-if="resource.targetType === 'handpicked'" class="d-flex flex-column">
                  <div
                    v-for="product in resource.handpickedProductIds"
                    :key="product._id"
                    class="pointer"
                    @click="gotoProduct(product)"
                  >
                    <div class="pt-1 d-flex align-center">
                      <v-avatar tile size="32" color="#dddd" class="text-center pointer mr-3">
                        <img
                          :src="
                            product && product.images[0]
                              ? imageHelpers.url(product.images[0].src)
                              : '/admin/img/no-image.png'
                          "
                          alt=""
                          srcset=""
                        />
                      </v-avatar>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on">{{ utilities.stringLimit(product.title, 20) }}</div>
                        </template>
                        <span> {{ product.title }}</span>
                      </v-tooltip>
                    </div>
                  </div>
                  <div v-for="collection in resource.handpickedCollectionIds" :key="collection._id">
                    <div class="pt-1 d-flex align-center">
                      <div class="image__container mr-1"><div class="placeholder style-1">A</div></div>
                      <div @click="gotoCollectionStore(collection)" class="pointer">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on">{{ utilities.stringLimit(collection.title, 20) }}</div>
                          </template>
                          <span> {{ collection.title }}</span>
                        </v-tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </template>
          </td>
          <!-- :style ở dưới để icon k bị xuống dòng khi hover vào customer -->
        </tr>
      </tbody>
      <template v-if="!$parent.isLoading && document && document.length > 0 && draggable">
        <draggable v-model="$parent.$parent.document" tag="tbody">
          <tr @mouseover="mouseOver(k)" @mouseleave="mouseLeave(k)" v-for="(resource, k) in document" :key="k">
            <!-- :style ở dưới để icon k bị xuống dòng khi hover vào customer -->
            <td
              v-for="(column, kc) in columns"
              :key="kc"
              :style="
                column.kind === 'selectable'
                  ? 'width:15px'
                  : column.kind === 'customer'
                  ? 'width:165px; padding: 20px 0;'
                  : ''
              "
            >
              <template v-if="column.kind === 'action'">
                <div @click="deleteResource(resource)">
                  <i class="fas fa-trash-alt pointer"></i>
                </div>
                <!-- <div>
                <i class="fas fa-edit"></i>
              </div> -->
              </template>
              <template v-if="column.kind === 'draggerable'">
                <div style="width: 15px" class="table-checkbox pointer">
                  <i class="fas fa-bars"></i>
                </div>
              </template>
              <template v-if="column.kind === 'selectable'">
                <div style="width: 15px" class="custom-control custom-checkbox table-checkbox pointer">
                  <input
                    @change="onchangeSelectableElement(resource)"
                    :checked="selectable.isSelected(makeSelectId(resource))"
                    type="checkbox"
                    class="custom-control-input pointer"
                    name="ordersSelect"
                    :id="makeSelectId(resource)"
                  />
                  <label :for="makeSelectId(resource)" class="custom-control-label">&nbsp;</label>
                </div>
              </template>
              <!-- <template v-if="column.kind === 'selectable'">
                <div style="width: 15px" class="custom-control custom-checkbox table-checkbox pointer">
                  D
                </div>
              </template> -->
              <template v-if="column.kind === 'viewProduct'">
                <div class="d-flex align-center">
                  <v-avatar tile size="40" color="#dddd" class="text-center pointer mr-3">
                    <img
                      :src="
                        resource.imageSrcs && resource.imageSrcs.length > 0 && resource.imageSrcs[0]
                          ? imageHelpers.url(resource.imageSrcs[0])
                          : imageHelpers.url(
                              resource.images && resource.images.length > 0 && resource.images[0].src
                                ? resource.images[0].src
                                : '/admin/img/no-image.png',
                            )
                      "
                      alt=""
                      srcset=""
                    />
                  </v-avatar>
                  <div>
                    <router-link class="invent" :to="{ name: routeName, params: { id: resource._id } }">{{
                      utilities.stringLimit(resource[column.field], 40)
                    }}</router-link>
                    <div v-if="!resource.published" class="unavailable">Unavailable</div>
                  </div>
                  <v-spacer />
                  <i @click.stop="gotoProduct(resource)" class="ml-5 pointer show-password far fa-eye fz-16"></i>
                  <i
                    v-if="column.kind === 'viewProductP'"
                    @click.stop="gotoPersonalize(resource)"
                    class="pointer mx-2 fab fa-intercom"
                  ></i>
                </div>
              </template>
              <template v-if="column.kind === 'viewProductinPer'">
                <div class="d-flex align-center">
                  <v-avatar tile size="40" color="#dddd" class="text-center pointer mr-3">
                    <img
                      :src="resource.images[0] ? imageHelpers.url(resource.images[0].src) : '/admin/img/no-image.png'"
                      alt=""
                      srcset=""
                    />
                  </v-avatar>
                  <div>
                    <router-link
                      class="invent"
                      :to="{ name: `website.products.update`, params: { id: resource._id } }"
                      >{{ utilities.stringLimit(resource[column.field], 40) }}</router-link
                    >
                    <div v-if="!resource.published" class="unavailable">Unavailable</div>
                  </div>
                  <v-spacer />
                  <i @click.stop="gotoProduct(resource)" class="ml-5 pointer show-password far fa-eye fz-16"></i>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <i
                        v-bind="attrs"
                        v-on="on"
                        v-if="column.kind === 'viewProductinPer'"
                        @click.stop="gotoPersonalize(resource)"
                        class="pointer mx-2 fab fa-intercom"
                      ></i>
                    </template>
                    <span>Setup Personalized Product</span>
                  </v-tooltip>
                </div>
              </template>
              <template v-if="column.kind === 'viewProductPersonalize'">
                <div class="d-flex align-center">
                  <v-avatar tile size="40" color="#dddd" class="text-center pointer mr-3">
                    <img
                      :src="resource.images[0] ? imageHelpers.url(resource.images[0].src) : '/admin/img/no-image.png'"
                      alt=""
                      srcset=""
                    />
                  </v-avatar>
                  <router-link class="invent" :to="{ name: routeName, params: { id: resource._id } }">{{
                    utilities.stringLimit(resource[column.field], 40)
                  }}</router-link>
                  <!-- <span @click="updateRouterCallback(resource._id)" class="table-link">
                  {{ utilities.stringLimit(resource[column.field], 40) }}
                </span> -->
                  <v-spacer />
                  <i @click.stop="gotoProduct(resource)" class="ml-5 pointer show-password far fa-eye fz-16"></i>

                  <!-- <i @click.stop="gotoProduct(resource)" class="ml-5 pointer show-password far fa-eye fz-16"></i> -->
                  <!-- <i @click.stop="gotoPersonalize(resource)" class="pointer mx-2 fab fa-intercom"></i> -->
                </div>
              </template>
              <template v-if="column.kind === 'viewCollection'">
                <div @click="updateRouterCallback(resource._id)" class="table-link">
                  <v-avatar tile size="40" color="#dddd" class="text-center pointer mr-3">
                    <img
                      :src="
                        resource.image && resource.image.src !== '' && resource.image.src !== null
                          ? imageHelpers.url(resource.image.src)
                          : '/admin/img/no-image.png'
                      "
                      alt=""
                      srcset=""
                    />
                  </v-avatar>
                  <router-link class="invent" :to="{ name: routeName, params: { id: resource._id } }">{{
                    utilities.stringLimit(resource[column.field], 40)
                  }}</router-link>
                  <!-- <span>
                  {{ utilities.stringLimit(resource[column.field], 50) }}
                </span> -->
                </div>
              </template>
              <template v-if="column.kind === 'text'">
                <div
                  :class="{
                    'text-capitalize': resource[column.field] != 'cod',
                    'text-uppercase': resource[column.field] == 'cod',
                  }"
                >
                  {{ resource[column.field] }}
                </div>
              </template>
              <template v-if="column.kind === 'text-status'">
                <div class="text-capitalize">
                  {{ changeName(resource[column.field]) }}
                </div>
              </template>
              <template v-if="column.kind === 'title-menufilter'">
                <!-- <div>
                <router-link :to="{ path: routeName, params: { id: resource._id } }">{{ resource.name }}</router-link>
              </div> -->
                <div class="pointer" @click="updateRouterCallback(resource._id ? resource._id : resource.id)">
                  <div class="table-link">{{ utilities.stringLimit(resource.name, 50) }}</div>
                </div>
              </template>
              <template v-if="column.kind === 'value-menufilter'">
                <div>
                  {{ resource.values.length > 0 ? getColorName(resource) : '' }}
                  <!-- {{ resource.values }} -->
                </div>
              </template>
              <template v-if="column.kind === 'text2'">
                <div class="">
                  {{ resource[column.field] }}
                </div>
              </template>
              <template v-if="column.kind === 'sku'">
                <div style="max-width: 80px; overflow: hidden; text-overflow: ellipsis" class="">
                  {{ resource[column.field] }}
                </div>
              </template>
              <template v-if="column.kind === 'text-inventory'">
                <div class="">
                  {{ resource[column.field] == 'continue' ? 'Continue selling' : 'Stop selling' }}
                </div>
              </template>
              <template v-if="column.kind === 'product-variant'">
                <div style="width: 150px">
                  <router-link class="invent" :to="{ name: routeName, params: { id: resource[column.product]._id } }">
                    {{ utilities.stringLimit(resource[column.product].title, 20) }}
                  </router-link>
                </div>
                <div class="">{{ resource[column.field] }}</div>
              </template>
              <template v-if="column.kind === 'update-quantity'">
                <div class="d-flex align-center custom-input">
                  <v-btn-toggle>
                    <v-btn
                      :color="actions[k].data.action == 'add' ? 'primary' : ''"
                      @click="actions[k].data.action = 'add'"
                      small
                      >ADD
                    </v-btn>
                    <v-btn
                      :color="actions[k].data.action == 'set' ? 'primary' : ''"
                      @click="actions[k].data.action = 'set'"
                      small
                      >SET
                    </v-btn>
                  </v-btn-toggle>
                  <v-text-field
                    style="width: 70px"
                    class="mt-0 input-no-boder-radius"
                    width="50px"
                    height="30px"
                    type="number"
                    hide-details
                    v-model="actions[k].data.inventoryQuantity"
                  ></v-text-field>
                  <v-btn small @click="updateQuantityCallback(actions[k])" color="primary">SAVE</v-btn>
                </div>
              </template>
              <template v-if="column.kind === 'title'">
                <div class="pointer" @click="updateRouterCallback(resource._id ? resource._id : resource.id)">
                  <div class="table-link">
                    <router-link
                      class="invent"
                      :to="{ name: routeName, params: { id: resource._id ? resource._id : resource.id } }"
                      ><span>{{ utilities.stringLimit(resource[column.field], 50) }}</span></router-link
                    >
                  </div>
                </div>
              </template>
              <template v-if="column.kind === 'title-sizechart'">
                <div class="pointer" @click="updateRouterCallback(resource._id ? resource._id : resource.id)">
                  <div class="table-link">
                    <!-- có 1 số shop cũ size chart ở dạng string, khi load trang nó lỗi luôn, nên phải ntn -->
                    <router-link
                      class="invent"
                      :to="{ name: routeName, params: { id: resource._id ? resource._id : resource.id } }"
                      ><span>{{
                        typeof resource[column.field] != 'string'
                          ? utilities.stringLimit(resource[column.field].join(', '), 60)
                          : utilities.stringLimit(resource[column.field], 60)
                      }}</span></router-link
                    >
                  </div>
                </div>
              </template>
              <template v-if="column.kind === 'viewOrderCheckRole'">
                <div
                  v-if="resource.offerType === 'postPurchase'"
                  class="pointer"
                  @click="
                    utilities.checkPermisson(`write_orders`, user)
                      ? checkRoleWriteOrder(resource, 3)
                      : $router.push({ name: 'no-access' })
                  "
                >
                  <div class="table-link">
                    <router-link
                      class="invent"
                      :to="{ name: routeName, params: { id: resource._id ? resource._id : resource.id } }"
                      ><span>{{ utilities.stringLimit(resource[column.field], 50) }}</span></router-link
                    >
                  </div>
                </div>
                <div
                  v-else
                  class="pointer"
                  @click="
                    utilities.checkPermisson(`write_orders`, user)
                      ? checkRoleWriteOrder(resource)
                      : $router.push({ name: 'no-access' })
                  "
                >
                  <div class="table-link">
                    <router-link
                      class="invent"
                      :to="{ name: routeName, params: { id: resource._id ? resource._id : resource.id } }"
                      ><span>{{ utilities.stringLimit(resource[column.field], 50) }}</span></router-link
                    >
                  </div>
                </div>
              </template>
              <template v-if="column.kind === 'view'">
                <div
                  v-if="resource.offerType === 'postPurchase'"
                  class="pointer"
                  @click="updateRouterCallback(resource._id ? resource._id : resource.id, { tab: 3 })"
                >
                  <div class="table-link">
                    <router-link
                      class="invent"
                      :to="{ name: routeName, params: { id: resource._id ? resource._id : resource.id } }"
                      ><span> {{ utilities.stringLimit(resource[column.field], 50) }} </span></router-link
                    >
                  </div>
                </div>
                <div v-else class="pointer" @click="updateRouterCallback(resource._id ? resource._id : resource.id)">
                  <div class="table-link">
                    <router-link
                      class="invent"
                      :to="{ name: routeName, params: { id: resource._id ? resource._id : resource.id } }"
                      ><span> {{ utilities.stringLimit(resource[column.field], 50) }} </span></router-link
                    >
                  </div>
                </div>
              </template>
              <template v-if="column.kind === 'checkoutAbandon'">
                <div class="pointer" @click="updateRouterCallback(resource._id ? resource._id : resource.id)">
                  <div class="table-link">
                    <router-link
                      class="invent"
                      :to="{ name: routeName, params: { id: resource._id ? resource._id : resource.id } }"
                      ><span> {{ utilities.stringLimit(resource[column.field], 50) }} </span></router-link
                    >
                  </div>
                </div>
              </template>
              <template v-if="column.kind === 'price'">
                <div class="text-center">{{ currency.priceFormat(resource[column.field]) }}</div>
              </template>
              <template v-if="column.kind === 'customerAbandoned'">
                <div class="text-left">
                  {{ utilities.stringLimit(resource[column.field] ? resource[column.field].full_name : '', 18) }}
                </div>
              </template>
              <template v-if="column.kind === 'customer'">
                <v-hover v-slot="{ hover }">
                  <div class="d-flex" style="">
                    {{
                      utilities.stringLimit(
                        resource[column.field].length > 0 ? resource[column.field][0].full_name : '',
                        18,
                      )
                    }}
                    <v-spacer></v-spacer>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-show="hover"
                          @click.stop="gotoCustomer(resource)"
                          class="ml-1 mdi mdi-account-search mdi-18px"
                          v-bind="attrs"
                          v-on="on"
                        ></v-icon>
                      </template>
                      <span>View customer</span>
                    </v-tooltip>
                  </div>
                </v-hover>
              </template>
              <template v-if="column.kind === 'rules'">
                <div v-for="(rule, k) in resource[column.field]" class="" :key="k">
                  <span v-if="getColumn(rule.column)">
                    {{ utilities.stringLimit(getColumn(rule.column).name, 50) }}
                  </span>
                  <span class="ml-1" v-if="getRule(rule.relation)">
                    {{ utilities.stringLimit(getRule(rule.relation).name, 50) }}
                  </span>
                  {{ utilities.stringLimit(rule.condition, 10) }}
                </div>
              </template>
              <template v-if="column.kind === 'datetime'">
                <div v-if="resource[column.field]">
                  <span v-if="column.type && column.type === 'utc'">
                    {{ datetime.formatUTC(resource[column.field], 'DD-MM-YYYY HH:mm:ss') }}
                  </span>
                  <span v-else>{{ datetime.format(resource[column.field], 'DD-MM-YYYY HH:mm:ss') }}</span>
                </div>
              </template>
              <template v-if="column.kind === 'date'">
                <div v-if="resource[column.field]">
                  <span v-if="column.type && column.type === 'utc'">
                    {{ datetime.formatUTC(resource[column.field], 'DD-MM-YYYY HH:mm:ss') }}
                  </span>
                  <span v-else>{{ datetime.format(resource[column.field], 'DD-MM-YYYY HH:mm:ss') }}</span>
                </div>
              </template>
              <template v-if="column.kind === 'status'">
                <v-chip
                  v-if="
                    resource[column.field] == 'active' ||
                      resource[column.field] == 'deny' ||
                      resource[column.field] == 'deactivate'
                  "
                  :color="resource[column.field] == 'active' ? 'green' : ''"
                  outlined
                >
                  {{ resource[column.field] }}
                </v-chip>
                <p v-else>
                  <v-chip :color="resource[column.field] == true ? 'green' : ''" outlined>
                    {{ resource[column.field] == 1 ? 'Ready' : 'Disabled' }}
                  </v-chip>
                </p>
              </template>
              <template v-if="column.kind === 'status-feed'">
                <v-chip v-if="resource[column.field] == 0">Wating</v-chip>
                <v-chip v-if="resource[column.field] == 1" class="orange">Processing</v-chip>
                <v-chip v-if="resource[column.field] == 2" color="green">Completed</v-chip>
                <v-chip v-if="resource[column.field] == 4" color="red">Error</v-chip>
              </template>
              <template v-if="column.kind === 'url'">
                <a @click="download(resource[column.field])">{{ utilities.stringLimit(resource[column.field], 40) }}</a>
                <v-icon @click="copyURL(resource[column.field])" class="ml-3">far fa-copy</v-icon>
                <v-chip
                  v-if="resource.totalKlaviyoFile"
                  class="ml-2 pointer"
                  @click="showListKlaviyo(resource.totalKlaviyoFile, resource._id)"
                >
                  Klaviyo
                </v-chip>
              </template>

              <!-- //showOffer -->
              <template v-if="column.kind === 'priority-custom'">
                <div style="width: 50px">
                  <v-text-field
                    :error="resource.priority < 0 ? true : false"
                    @blur="resource.priority > 0 ? submitPriority(resource) : null"
                    type="number"
                    min="1"
                    hide-details
                    v-model.number="resource.priority"
                  ></v-text-field>
                </div>
              </template>
              <template v-if="column.kind === 'showOffer'">
                <div v-if="resource.targetType === 'all'" class="d-flex align-center">
                  <div class="image__container mr-1"><div class="placeholder style-1">A</div></div>
                  Any product
                </div>
                <div
                  v-if="resource.targetType === 'product' && resource.typeBundle == 'smart'"
                  class="d-flex align-center"
                >
                  <div class="image__container mr-3"><div class="placeholder style-1">A</div></div>
                  Any product
                </div>
                <div
                  v-if="resource.recommendType === 'product' && resource.offerType === 'handpicked'"
                  class="d-flex flex-column"
                >
                  <div v-for="(product, k) in resource.productRecommendIds" :key="product._id" class="pointer">
                    <div
                      @click="gotoProduct(product)"
                      v-if="k <= 2 || !resource.seeMore"
                      class="pt-1 d-flex align-center"
                    >
                      <v-avatar tile size="32" color="#dddd" class="text-center pointer mr-3">
                        <img
                          :src="
                            product && product.images[0]
                              ? imageHelpers.url(product.images[0].src)
                              : '/admin/img/no-image.png'
                          "
                          alt=""
                          srcset=""
                        />
                      </v-avatar>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on">{{ utilities.stringLimit(product.title, 20) }}</div>
                        </template>
                        <span> {{ product.title }}</span>
                      </v-tooltip>
                      <!-- <div>{{ utilities.stringLimit(product.title, 20) }}</div> -->
                    </div>
                    <div class="link-add" v-if="k == 3 && resource.seeMore">
                      <a href="#" @click="resource.seeMore = false"> See more </a>
                    </div>
                    <div class="link-add" v-if="k === resource.productRecommendIds.length - 1 && !resource.seeMore">
                      <a href="#" @click="resource.seeMore = true"> See less </a>
                    </div>
                  </div>
                </div>
                <div
                  v-if="resource.targetType === 'product' && resource.targetType === 'product'"
                  class="d-flex flex-column"
                >
                  <div v-for="(product, k) in resource.productTargetIds" :key="product._id" class="pointer">
                    <div
                      @click="gotoProduct(product)"
                      v-if="k <= 2 || !resource.seeMore"
                      class="pt-1 d-flex align-center"
                    >
                      <v-avatar tile size="32" color="#dddd" class="text-center pointer mr-3">
                        <img
                          :src="
                            product && product.images[0]
                              ? imageHelpers.url(product.images[0].src)
                              : '/admin/img/no-image.png'
                          "
                          alt=""
                          srcset=""
                        />
                      </v-avatar>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on">{{ utilities.stringLimit(product.title, 20) }}</div>
                        </template>
                        <span> {{ product.title }}</span>
                      </v-tooltip>
                    </div>
                    <div class="link-add" v-if="k == 3 && resource.seeMore">
                      <a href="#" @click="resource.seeMore = false"> See more </a>
                    </div>
                    <div class="link-add" v-if="k === resource.productTargetIds.length - 1 && !resource.seeMore">
                      <a href="#" @click="resource.seeMore = true"> See less </a>
                    </div>
                  </div>
                </div>
                <div v-if="resource.targetType === 'collection'" class="d-flex flex-column">
                  <div v-for="(collection, k) in resource.collectionTargetIds" :key="collection._id">
                    <div v-if="k <= 2 || !resource.seeMore" class="pt-1 d-flex align-center">
                      <div class="image__container mr-1"><div class="placeholder style-1">A</div></div>
                      <div @click="gotoCollectionStore(collection)" class="pointer">
                        {{ utilities.stringLimit(collection.title, 20) }}
                      </div>
                    </div>
                    <div class="link-add" v-if="k == 3 && resource.seeMore">
                      <a href="#" @click="resource.seeMore = false"> See more </a>
                    </div>
                  </div>
                </div>
              </template>
              <template v-if="column.kind === 'showHandpickedTarget' && resource.offerType === 'handpicked'">
                <div
                  v-if="resource.handpickedCollectionIds.length === 0 && resource.handpickedProductIds.length === 0"
                  class="d-flex align-center"
                >
                  <div class="image__container mr-1"><div class="placeholder style-1">A</div></div>
                  Any product
                </div>
                <template>
                  <div v-if="resource.targetType === 'handpicked'" class="d-flex flex-column">
                    <div
                      v-for="product in resource.handpickedProductIds"
                      :key="product._id"
                      class="pointer"
                      @click="gotoProduct(product)"
                    >
                      <div class="pt-1 d-flex align-center">
                        <v-avatar tile size="32" color="#dddd" class="text-center pointer mr-3">
                          <img
                            :src="
                              product && product.images[0]
                                ? imageHelpers.url(product.images[0].src)
                                : '/admin/img/no-image.png'
                            "
                            alt=""
                            srcset=""
                          />
                        </v-avatar>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on">{{ utilities.stringLimit(product.title, 20) }}</div>
                          </template>
                          <span> {{ product.title }}</span>
                        </v-tooltip>
                      </div>
                    </div>
                    <div v-for="collection in resource.handpickedCollectionIds" :key="collection._id">
                      <div class="pt-1 d-flex align-center">
                        <div class="image__container mr-1"><div class="placeholder style-1">A</div></div>
                        <div @click="gotoCollectionStore(collection)" class="pointer">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">{{ utilities.stringLimit(collection.title, 20) }}</div>
                            </template>
                            <span> {{ collection.title }}</span>
                          </v-tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </template>
            </td>
          </tr>
        </draggable>
      </template>
    </table>
    <ListKlaviyo :total="3" ref="listKlaviyo" />
    <v-col cols="12" v-if="!$parent.isLoading && document && document.length === 0" class="d-flex justify-center">
      <Empty />
    </v-col>
    <v-col cols="12" v-if="!$parent.isLoading && document && document.length === 0" class="d-flex justify-center">
      <v-divider></v-divider>
    </v-col>
    <v-snackbar v-model="snackbar" :timeout="2000">Copied</v-snackbar>
  </div>
</template>

<script>
import ListKlaviyo from './ListKlaviyo';
import datetime from '@/helpers/datetime';
import utilities from '@/helpers/utilities';
import imageHelpers from '@/helpers/image';
import { imageFake } from '@/const/image';
import currency from '@/helpers/currency';
import STORAGE_NAME from '@/const/storage';
import axios from 'axios';
import { offerApi } from '@/apis/offers';
import { minValue } from 'vuelidate/lib/validators';
import { log } from 'handlebars';
import { mapState } from 'vuex';
import moment from 'moment';
import { personalizesApi } from '@/apis/personalizes';
import { mapGetters } from 'vuex';
import event from '@/plugins/event-bus';
import draggable from 'vuedraggable';
import { FETCH_STORES } from '@/store/actions.type';

export default {
  components: {
    draggable,
    ListKlaviyo,
  },
  props: {
    draggable: {
      type: Boolean,
      default: false,
    },
    routeName: {
      type: String,
    },
    document: {
      default: null,
    },
    columns: {
      type: Object,
      default() {
        return {};
      },
    },
    /**
     * Selectable object
     */
    selectable: {
      default: null,
    },
    updateRouterCallback: {
      type: Function,
      default(id) {
        return { name: 'home' };
      },
    },
    updateQuantityCallback: {
      type: Function,
      default(data) {},
    },
    deleteResource: {
      type: Function,
      default(data) {},
    },
    callbackSelection: {
      type: Function,
      default(ids) {},
    },
    callbackAction: {
      type: Function,
      default(action, ids) {},
    },
  },
  data() {
    return {
      // isDisplayCustomer: [],
      snackbar: false,
      selectableId: 'abcs',
      quantity: 0,
      actions: [],
      resources: [],
      datetime,
      utilities,
      imageHelpers,
      imageFake,
      currency,
      moment,
      // onListKlaviyo: [],
      titleRules: [
        {
          name: 'Product title',
          value: 'title',
        },
        {
          name: 'Product type',
          value: 'productType',
        },
        {
          name: 'Product tags',
          value: 'tags',
        },
        {
          name: 'Product vendor',
          value: 'vendor',
        },
        {
          name: 'Product price',
          value: 'variant.price',
        },
        {
          name: 'Compare at price',
          value: 'variant.compareAtPrice',
        },
        {
          name: 'Weight',
          value: 'variant.weight',
        },
        {
          name: 'Inventory stock',
          value: 'variant.inventoryQuantity',
        },
        {
          name: 'Variant title',
          value: 'variant.title',
        },
        //Variant title
      ],
      stringRules: [
        {
          type: 'equal',
          name: 'is equal to',
        },
        {
          type: 'notEqual',
          name: 'is not equal to',
        },
        {
          type: 'contain',
          name: 'contains',
        },
        {
          type: 'notContain',
          name: `doesn't contain`,
        },
        {
          type: 'startWith',
          name: 'starts with',
        },
        {
          type: 'endWith',
          name: 'ends with',
        },
      ],
    };
  },
  async created() {
    this.document.forEach(i => (i.isDisplayCustomer = false));
    // console.log(this.document);
    this.document.forEach(element => {
      let object = {
        ids: [],
        data: {},
      };
      object.ids.push(element._id);
      object.data.action = 'add';
      object.data.inventoryQuantity = 0;
      this.actions.push(object);
    });
    await this.$store.dispatch(FETCH_STORES);
  },
  computed: {
    ...mapGetters(['user', 'stores']),
    ...mapState({
      pagePreference: state => state.pagePreference.preference,
    }),
  },
  methods: {
    changeName(a) {
      let result;
      switch (a) {
        case 'not_sent':
          result = 'not sent';
          break;
        case 'not_recovered':
          result = 'not recovered';
          break;
        default:
          result = a;
      }
      return result;
    },
    getColorName(item) {
      let a = [];
      if (item.values[0].value) {
        item.values.forEach(i => a.push(i.value));
      } else {
        a.push(item.values);
      }
      return a.join(', ');
    },
    mouseOver(k) {
      this.document[k].isDisplayCustomer = true;
      // console.log(this.document[a].isDisplayCustomer);
    },
    mouseLeave(k) {
      this.document[k].isDisplayCustomer = false;
      // console.log(this.document[a].isDisplayCustomer);
    },
    async gotoPersonalize(item) {
      console.log(item, item);
      try {
        let res = await personalizesApi.getPresionalizeWithProduct(item._id);
        if (res && res.data === '') {
          this.$router.push({
            name: 'website.product-personalized.create',
            params: {
              id: item._id,
            },
          });
        } else {
          this.$router.push({
            name: 'website.product-personalized.detail',
            params: {
              id: res.data.id,
            },
          });
        }
        console.log(res);
      } catch (error) {}
    },
    getColumn(value) {
      return this.titleRules.find(c => c.value === value);
    },
    getRule(type) {
      return this.stringRules.find(rule => rule.type === type);
    },
    async submitPriority(item) {
      try {
        let response = await offerApi.update(item._id, { priority: item.priority });
        console.log(response);
      } catch (error) {
        console.log(error);
        //empty
      }
    },
    async download(url) {
      try {
        let data = await axios.get(url);
        let text = data.data;
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', 'feeds');
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      } catch (error) {
        console.log(error);
      }
    },
    onchangeSelectable() {
      if (this.selectable.isSelectAll()) {
        this.selectable.unselectAll();
        // onCountProductss là điều kiện bật tắt số lượng countt sp

        this.$parent.onCountProductss = false;
        // console.log('resTable1');
      } else {
        this.checkAllItem();
        this.$parent.onCountProductss = true;
        // console.log('resTable2');
      }
      event.$emit('countProduct');
      this.callbackSelection(this.selectable.getIds());
      // console.log('resTable1');
    },
    onchangeSelectableElement(resource) {
      if (this.selectable.isSelected(this.makeSelectId(resource))) {
        this.selectable.unselect(this.makeSelectId(resource), this.document.length);
        this.$parent.totalProductClicked += 1;
      } else {
        this.selectable.select(this.makeSelectId(resource), this.document.length);
        this.$parent.totalProductClicked -= 1;
      }
      this.callbackSelection(this.selectable.getIds());
    },
    checkAllItem() {
      let ids = this.document.map(item => (item._id ? item._id : item.id));
      // console.log(this.document);
      this.selectable.selectAll(ids);
    },
    makeSelectId(resource) {
      // console.log(resource.id || resource._id);
      return resource && resource.id ? resource.id : resource._id;
    },
    gotoCollectionStore(item) {
      if (item.handle) {
        window.open('https://' + this.$store.state.auth.accountInfo.rDomain + `/collections/${item.handle}`);
      }
    },
    gotoProduct(item) {
      const storeId = localStorage.getItem('store-id');
      const store = this.stores.find(store => store.id === storeId);
      if (store) {
        const domain = store?.primaryDomain || store?.storeDetail?.defaultSystemDomain;
        window.open(`https://${domain}/products/${item.handle}`);
      } else {
        window.open('https://' + this.$store.state.auth.accountInfo.rDomain + `/products/${item.handle}`);
      }
    },
    gotoCustomer(item) {
      // console.log(item);
      this.$router.push({ name: 'website.customer.update', params: { id: item.customer } });
    },
    copyURL(text) {
      var textField = document.createElement('textarea');
      textField.innerText = text;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand('copy');
      textField.remove();
      this.snackbar = true;
    },
    showListKlaviyo(total, id) {
      console.log(1);
      this.$refs.listKlaviyo.total = total;
      this.$refs.listKlaviyo.feedId = id;
      this.$refs.listKlaviyo.onListKlaviyo = true;
    },
    checkRoleWriteOrder(resource, tab) {
      this.utilities.checkPermisson(`write_orders`, this.user)
        ? this.$router.push({
            name: 'website.orders.detail',
            params: { id: resource._id ? resource._id : resource.id },
            query: { tab: tab ? tab : null },
          })
        : this.$router.push({ name: 'no-access' });
    },
  },
};
</script>
<style type="text/css" lang="scss">
.resource-table {
  .w-100 {
    width: 100px;
  }
  .image__container {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    background: #f5f6f7;
    .style-1 {
      background-color: #5c48dc;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      border-radius: 4px;
    }
  }
  .table-checkbox .custom-control-label::before,
  .table-checkbox .custom-control-label::after {
    top: 0.2rem;
    transform: translateY(0%);
  }
  .text-avatar__initial-text {
    top: 12px;
    font-size: 18px;
  }
}
#resources-table {
  border-collapse: collapse;
  width: 100%;
}
#resources-table td,
#resources-table th {
  border-bottom: 1px solid var(--v-border-base);
  padding: 8px 12px;
}
#resources-table tr:nth-child(even) {
  // background-color: #f2f2f2;
}
#resources-table tr:hover {
  // background-color: #ddd;
}
#resources-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  opacity: 0.8;
  text-transform: capitalize;
}
.unavailable {
  background-color: #f7e7e6;
  color: #ee4b38;
  width: fit-content;
  border-radius: 3px 3px 3px 3px;
  font-size: 12px;
  font-weight: 400;
  padding: 2px 6px;
}
.invent {
  color: #0061af !important;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
.table-link {
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  cursor: pointer;
  color: var(--v-primary-base) !important;
  text-decoration: none;
  span {
    font-weight: 700;
    font-size: 15px;
  }
  &:hover {
    text-decoration: underline;
  }
  .rll {
    text-decoration: none !important;
  }
}
.display1 {
  color: red;
  display: block;
}
</style>
