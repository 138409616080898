import Request from '../index';
import STORAGE_NAME from '@/const/storage';

const cloneRequest = new Request();
let token = localStorage.getItem(STORAGE_NAME.ACCESS_TOKEN);
let storeDomain = 'accounts.dcomcy.com';
if (token) {
  cloneRequest.setBaseURL(`https://${storeDomain}/api/`);
  cloneRequest.setToken(token);
}
export default cloneRequest;
