var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"no-gutters":""}},[(_vm.searchable)?_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12"}},[(_vm.searchable)?_c('div',{staticClass:"pr-3 input-group input-group-flush input-group-merge mt-3 mb-2",staticStyle:{"flex":"1"}},[_c('v-text-field',{staticClass:"mt-0",attrs:{"placeholder":"Search","autocomplete":"off","prepend-inner-icon":"mdi-magnify pointer","hide-details":""},model:{value:(_vm.$parent.searchString),callback:function ($$v) {_vm.$set(_vm.$parent, "searchString", $$v)},expression:"$parent.searchString"}})],1):_vm._e(),(_vm.filterFields && _vm.filterFields.length > 0)?_c('FilterMoreDrawer',{attrs:{"isTable":true,"drawer":_vm.drawer,"fields":_vm.filterFields,"filter":_vm.filterData,"sync-filter-query":false,"callback":_vm.setTmpFilterData,"callbackDone":_vm.commitFilterData,"timezone":_vm.pagePreference.standardTimezone},on:{"update:drawer":function($event){_vm.drawer=$event}}}):_vm._e()],1):_vm._e(),(_vm.filterData && _vm.filterData.length > 0)?_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex flex-wrap"},_vm._l((_vm.filterData),function(i,k){return _c('div',{key:i.field,staticStyle:{"width":"max-content"}},[(i.value != '' || typeof i.value == 'boolean')?_c('v-chip',{staticClass:"ma-2",attrs:{"close":""},on:{"click:close":function($event){return _vm.removeFilterData(k)}}},[_c('span',[_vm._v(_vm._s(_vm.convertFilterDataName(i.field)))]),(i.compare)?_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.convertFilterDataName(i.compare)))]):_vm._e(),(Array.isArray(i.value) && i.field !== 'orderName')?_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.convertOrderStatus(i.value)))]):_vm._e(),(
              !Array.isArray(i.value) &&
                !i.compare &&
                i.field !== 'hasImage' &&
                i.field !== 'hasVariantImage' &&
                i.field !== 'fulfillmentDate' &&
                i.field !== 'orderDate'
            )?_c('span',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(i.nameDislay ? i.nameDislay : i.field == 'collectionIds' ? _vm.collectionName : i.value)+" ")]):_vm._e(),(
              _vm.filterFields.find(function (f) { return f.field === i.field; }).type.name !== 'autocomplete' &&
              _vm.filterFields.find(function (f) { return f.field === i.field; }).type.name !== 'checkbox' && // do v-model có _ (PARTIAL_REFUND)
              _vm.filterFields.find(function (f) { return f.field === i.field; }).type.name !== 'order-name' && // từ đây xuống v-model kiểu khác
                _vm.filterFields.find(function (f) { return f.field === i.field; }).type.name !== 'country' &&
                _vm.filterFields.find(function (f) { return f.field === i.field; }).kind !== 'Obj' &&
                _vm.filterFields.find(function (f) { return f.field === i.field; }).type.name !== 'email'
            )?_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(i.value))]):_vm._e(),(_vm.filterFields.find(function (f) { return f.field === i.field; }).field == 'orderName')?_c('span',{},[_vm._v(" Order Number: "+_vm._s(i.value[0])+" to "+_vm._s(i.value[1])+" ")]):_vm._e(),(_vm.filterFields.find(function (f) { return f.field === i.field; }).field == 'orderDate')?_c('span',{},[_vm._v(" Order Date: "+_vm._s(i.value.startDate)+" to "+_vm._s(i.value.endDate)+" ")]):_vm._e(),(_vm.filterFields.find(function (f) { return f.field === i.field; }).field == 'fulfillmentDate')?_c('span',{},[_vm._v(" Fulfillment Date: "+_vm._s(i.value.startDate)+" to "+_vm._s(i.value.endDate)+" ")]):_vm._e()]):_vm._e()],1)}),0)]):_vm._e(),(!_vm.$parent.isLoading && _vm.document)?_c('v-col',{attrs:{"cols":"12"}},[(_vm.selectable.getIds() && _vm.selectable.getIds().length > 0)?_c('div',{staticClass:"mb-2 resources-header--sticky d-flex align-center"},[_c('v-btn-toggle',{attrs:{"dense":""}},[_c('v-btn',[_c('div',{staticClass:"custom-control custom-checkbox table-checkbox pointer pb-1"},[_c('input',{staticClass:"custom-control-input pointer",attrs:{"type":"checkbox","name":"ordersSelect","id":_vm.selectableId},domProps:{"checked":_vm.checkSelectAll()},on:{"change":_vm.onchangeSelectable}}),_c('label',{class:'pointer custom-control-label ',attrs:{"for":_vm.selectableId}})]),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.isOnCountProducts ? _vm.selectable.getIds().length : _vm.numberProductDisplay)+" selected")])]),(_vm.actionItems && _vm.actionItems.length > 0)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({on:{"click":function($event){_vm.openList = true}}},'v-btn',attrs,false),on),[_vm._v(" Actions "),_c('v-icon',{attrs:{"right":"","size":18}},[_vm._v("mdi-chevron-down")])],1)]}}],null,false,3217391578),model:{value:(_vm.openList),callback:function ($$v) {_vm.openList=$$v},expression:"openList"}},[_c('v-list',{staticClass:"pa-0",attrs:{"dense":""}},_vm._l((_vm.actionItems),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){_vm.callbackAction(item, _vm.selectable.getIds())}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1):_vm._e()],1),(
          _vm.countt >= 50 &&
            _vm.selectable.getIds().length >= 50 &&
            _vm.isOnCountProducts &&
            (_vm.resourceType == 'collections' || _vm.resourceType == 'products')
        )?_c('span',{staticClass:"px-3 primary--text select-all-product",staticStyle:{"line-height":"48px"},on:{"click":function($event){return _vm.selectAllProducts()}}},[_vm._v(" Select all "+_vm._s(_vm.countt)+" products across all pages ")]):_vm._e(),(_vm.isClearAll && (_vm.resourceType == 'products' || _vm.resourceType == 'collections'))?_c('span',{staticClass:"px-3 primary--text select-all-product",staticStyle:{"line-height":"48px"},on:{"click":function($event){return _vm.clearAllProducts()}}},[_vm._v(" Clear All ")]):_vm._e()],1):_vm._e(),(_vm.selectable.getIds().length > 0)?_c('v-divider'):_vm._e(),_c('ResourceTable',{attrs:{"update-router-callback":_vm.updateRouterCallback,"update-quantity-callback":_vm.updateQuantityCallback,"callbackSelection":_vm.callbackSelection,"document":_vm.document,"selectable":_vm.selectable,"draggable":_vm.draggable,"columns":_vm.columns,"deleteResource":_vm.deleteResource,"routeName":_vm.routeName,"callback-action":_vm.callbackAction}})],1):_vm._e(),(_vm.$parent.isLoading)?_c('v-col',{staticClass:"d-flex justify-center mt-8",attrs:{"cols":"12"}},[_c('v-skeleton-loader',{attrs:{"width":"100%","type":"card"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }