<template>
  <!-- <v-row style="margin: 0px !important;"> -->
  <v-row style="margin: 0px !important" class="select-status">
    <v-col cols="4" sm="4">
      <v-btn @click="display1" class="font-weight-regular w-100 relative1">Email status</v-btn>
      <div class="cb absolute1" v-if="email">
        <v-checkbox
          @change="changeSelect"
          v-model="checkValueEmail"
          value="sent"
          label="Sent"
          hide-details
          color="#0061AF"
        ></v-checkbox>
        <v-checkbox
          @change="changeSelect"
          v-model="checkValueEmail"
          value="not_sent"
          label="Not sent"
          hide-details
          color="#0061AF"
        ></v-checkbox>
        <v-checkbox
          class="fz14"
          @change="changeSelect"
          v-model="checkValueEmail"
          value="scheduled"
          label="Scheduled"
          hide-details
          color="#0061AF"
        ></v-checkbox>
      </div>
    </v-col>
    <v-col cols="4" sm="4">
      <v-btn @click="display2" class="font-weight-regular w-100 relative1">SMS status</v-btn>
      <div class="cb absolute1" v-if="sms">
        <v-checkbox
          @change="changeSelect"
          v-model="checkValueSMS"
          value="sent"
          label="Sent"
          hide-details
          color="#0061AF"
        ></v-checkbox>
        <v-checkbox
          @change="changeSelect"
          v-model="checkValueSMS"
          value="not_sent"
          label="Not sent"
          hide-details
          color="#0061AF"
        ></v-checkbox>
        <v-checkbox
          @change="changeSelect"
          v-model="checkValueSMS"
          value="fieled"
          label="Failed"
          hide-details
          color="#0061AF"
        ></v-checkbox>
        <v-checkbox
          @change="changeSelect"
          v-model="checkValueSMS"
          value="scheduled"
          label="Scheduled"
          hide-details
          color="#0061AF"
        ></v-checkbox>
      </div>
    </v-col>
    <v-col cols="4" sm="4">
      <v-btn @click="display3" class="font-weight-regular w-100 relative1">Recovery status</v-btn>
      <div class="cb absolute1" v-if="recovery">
        <v-checkbox
          @change="changeSelect"
          v-model="checkValueRecovery"
          value="recovered"
          label="Recovered"
          hide-details
          color="#0061AF"
        ></v-checkbox>
        <v-checkbox
          @change="changeSelect"
          v-model="checkValueRecovery"
          value="not_recovered"
          label="Not recovered"
          hide-details
          color="#0061AF"
        ></v-checkbox>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      checkValueEmail: [],
      checkValueSMS: [],
      checkValueRecovery: [],
      email: false,
      recovery: false,
      sms: false,
    };
  },
  mounted() {
    console.log(this.$route.query);
    if (this.$route.query.emailStatus) {
      this.checkValueEmail = this.$route.query.emailStatus.split(',');
    }
    if (this.$route.query.smsStatus) {
      this.checkValueSMS = this.$route.query.smsStatus.split(',');
    }
    if (this.$route.query.recoveryStatus) {
      this.checkValueRecovery = this.$route.query.recoveryStatus.split(',');
    }
  },
  methods: {
    // result() {
    //   console.log(this.checkValue);
    // },
    // method của select
    changeSelect() {
      let data = {};
      if (this.checkValueEmail.length > 0) {
        data.emailStatus = this.checkValueEmail.join();
      }
      if (this.checkValueSMS.length > 0) {
        data.smsStatus = this.checkValueSMS.join();
      }
      if (this.checkValueRecovery.length > 0) {
        data.recoveryStatus = this.checkValueRecovery.join();
      }
      this.$emit('getSelect', data);
    },
    display1() {
      this.email = !this.email;
      this.recovery = false;
      this.sms = false;
    },
    display2() {
      this.sms = !this.sms;
      this.recovery = false;
      this.email = false;
    },
    display3() {
      this.recovery = !this.recovery;
      this.email = false;
      this.sms = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.select-status {
  .absolute1 {
    position: absolute;
  }
  .relative1 {
    position: relative;
  }
  .cb {
    z-index: 5;
    background-color: white;
    border: 1px solid #e6e6e6;
    box-shadow: 0 0 0 1px rgba(39, 44, 48, 0.05);
    width: 117px;
    padding: 5px;
    margin-top: 10px;
    animation: mymove1 0.4s;
    .v-input__slot {
      .v-input--selection-controls__input {
        .v-label {
          font-size: 14px !important;
        }
        .v-input {
          font-size: 14px !important;
        }
      }
    }
  }
  @keyframes mymove1 {
    from {
      top: 143px;
    }
    to {
      top: 113px;
    }
  }
  .w-100 {
    width: 100%;
  }
  .v-input--selection-controls {
    margin: 3px 0;
  }

  // .theme--light {
  //   font-size: 14px !important;
  // }
}
</style>
