<template>
  <!-- <v-row style="margin: 0px !important;"> -->
  <v-row style="margin: 0px !important;" class="select-fulfill">
    <v-col cols="6" sm="6">
      <v-btn @click="display2" class="font-weight-regular w-100 relative"
        ><v-icon color="#989898" left> mdi-map-marker </v-icon>Capture Status<v-icon dark right>
          mdi-menu-down
        </v-icon></v-btn
      >
      <div class="cb absolute" v-if="payment">
        <v-checkbox
          @change="changeSelect"
          v-model="checkPaymentStatus"
          value="paid"
          label="Paid"
          hide-details
          color="#0061AF"
        ></v-checkbox>
        <v-checkbox
          @change="changeSelect"
          v-model="checkPaymentStatus"
          value="canceled"
          label="Canceled"
          hide-details
          color="#0061AF"
        ></v-checkbox>
        <v-checkbox
          @change="changeSelect"
          v-model="checkPaymentStatus"
          value="refunded"
          label="Refunded"
          hide-details
          color="#0061AF"
        ></v-checkbox>
        <v-checkbox
          @change="changeSelect"
          v-model="checkPaymentStatus"
          value="partially_refunded"
          label="Partially Refunded"
          hide-details
          color="#0061AF"
        ></v-checkbox>
        <v-checkbox
          @change="changeSelect"
          v-model="checkPaymentStatus"
          value="disputed"
          label="Disputed"
          hide-details
          color="#0061AF"
        ></v-checkbox>
      </div>
    </v-col>
    <v-col cols="6" sm="6">
      <v-btn @click="display1" class="font-weight-regular w-100 relative"
        ><v-icon color="#989898" left> mdi-map-marker </v-icon>Fulfillment Status<v-icon dark right>
          mdi-menu-down
        </v-icon></v-btn
      >
      <div class="cb absolute" v-if="fulfill">
        <v-checkbox
          @change="changeSelect"
          v-model="checkFulfillStatus"
          value="unfulfilled"
          label="Unfulfilled"
          hide-details
          color="#0061AF"
        ></v-checkbox>
        <v-checkbox
          @change="changeSelect"
          v-model="checkFulfillStatus"
          value="partially_unfulfilled"
          label="Partially Unfulfilled"
          hide-details
          color="#0061AF"
        ></v-checkbox>
        <v-checkbox
          @change="changeSelect"
          v-model="checkFulfillStatus"
          value="fulfilled"
          label="Fulfilled"
          hide-details
          color="#0061AF"
        ></v-checkbox>
        <v-checkbox
          @change="changeSelect"
          v-model="checkFulfillStatus"
          value="processing"
          label="Processing"
          hide-details
          color="#0061AF"
        ></v-checkbox>
        <v-checkbox
          @change="changeSelect"
          v-model="checkFulfillStatus"
          value="partially_processing"
          label="Partially Processing"
          hide-details
          color="#0061AF"
        ></v-checkbox>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      checkPaymentStatus: [],
      checkFulfillStatus: [],
      fulfill: false,
      payment: false,
    };
  },
  mounted() {
    console.log(this.$route.query);
    if (this.$route.query.paymentStatus) {
      this.checkPaymentStatus = this.$route.query.paymentStatus.split(',');
    }
    if (this.$route.query.fulfillStatus) {
      this.checkFulfillmentStatus = this.$route.query.fulfillmentStatus.split(',');
    }
  },
  methods: {
    // method của select
    changeSelect() {
      let data = {};
      if (this.checkPaymentStatus.length > 0) {
        data.paymentStatus = this.checkPaymentStatus.join();
      }
      if (this.checkFulfillStatus.length > 0) {
        data.fulfillmentStatus = this.checkFulfillStatus.join();
      }
      this.$emit('getSelect', data);
    },
    display1() {
      this.fulfill = !this.fulfill;
      this.payment = false;
    },
    display2() {
      this.payment = !this.payment;
      this.fulfill = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.select-fulfill {
  .absolute {
    position: absolute;
  }
  .relative {
    position: relative;
  }
  .cb {
    z-index: 5;
    background-color: white;
    border: 1px solid #e6e6e6;
    box-shadow: 0 0 0 1px rgba(39, 44, 48, 0.05);
    width: 177px;
    padding: 5px;
    margin-top: 10px;
    animation: mymove 0.4s;
  }
  @keyframes mymove {
    from {
      top: 243px;
    }
    to {
      top: 213px;
    }
  }
  .w-100 {
    width: 100%;
  }
  .v-input--selection-controls {
    margin: 3px 0;
  }
  /* .theme--light.v-label {
  color: black;
} */
  .v-label {
    font-size: 14px !important;
  }
}
</style>
