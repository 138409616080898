import storeRequest from './request/storeRequest';
import cloneRequest from './request/cloneRequest';
import STORAGE_NAME from '@/const/storage';
let storeId = localStorage.getItem(STORAGE_NAME.STORE_ID);
storeRequest.setStoreId(storeId);
let path = '/stores/config/domains/';

function list() {
  return storeRequest.get(path);
}

function connect(data) {
  return storeRequest.post(path, data);
}

function disConnect(data) {
  return storeRequest.delete(path, data);
}
function addCheckoutDomain(data) {
  return storeRequest.post('/stores/config/update-domain-checkout', data);
}
function checkEnableRef() {
  return storeRequest.get('/stores/public/onlinestore/themes/page');
}
function buyNewDomain(data) {
  return storeRequest.post('/stores/config/buy-domains', data);
}
function getListPendingDomain() {
  return storeRequest.get('/stores/config/pending-domains');
}
function deleteDomainPending(data) {
  return storeRequest.delete('/stores/config/pending-domain', data);
}
function checkDomain(domain) {
  return storeRequest.post('/stores/admin/check-domain', {
    domain,
    storeId,
  });
}
export const domainService = {
  list,
  connect,
  disConnect,
  addCheckoutDomain,
  checkEnableRef,
  buyNewDomain,
  getListPendingDomain,
  deleteDomainPending,
  checkDomain,
};
